<script setup lang="ts">
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import { useEventListener } from '@vueuse/core';
import useMessageHandler from '~/composables/useMessageHandler';

const panelVertical: string = 'casino';
const panelCultureCode: string = 'en-US';
const panel = ref();
const runtimeConfig = useRuntimeConfig();
const auth = useAuthStore();
const site = useSiteStore();

useHead({
  script: [
    {
      src: runtimeConfig.public.notificationPanelUrl,
      type: 'module',
    },
  ],
});

onMounted(() => {
  useEventListener(
    panel.value,
    'handleNotificationActions',
    (event: CustomEvent) => {
      const action = event.detail.action;
      const actionValue = event.detail.actionValue;
      useMessageHandler({ action, actionValue });
      site.explicitToggleFeature({
        feature: 'displayNotifications',
        toggle: false,
      });
      auth.toggleAccountModal(false);
      panel.value.removeAttribute('opened');
    }
  );
  useEventListener(panel.value, 'closePanel', () => {
    site.explicitToggleFeature({
      feature: 'displayNotifications',
      toggle: false,
    });
    panel.value.removeAttribute('opened');
  });

  useEventListener(
    panel.value,
    'updateNotificationsCount',
    (event: CustomEvent) => {
      site.setUnreadNotificationsCount(event.detail.unread);
    }
  );
});

watch(
  () => site.featureDisplay({ feature: 'displayNotifications' }),
  (value) =>
    !value
      ? panel.value.removeAttribute('opened')
      : panel.value.setAttribute('opened', '')
);
</script>

<template>
  <notifications-panel
    ref="panel"
    id="panelId"
    :accesstoken="auth.access_token"
    :vertical="panelVertical"
    :culturecode="panelCultureCode"
    :regioncode="site?.getRegionCode"
    :colorscheme="useColorMode().preference"
    licence="jackpotcityafrica"
    enablepushnotifications="on"
  />
</template>
